<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        { 
          label: 'Manajemen Panduan Diet', href: '/admin/coaching-challenge',
        },
        { 
          label: 'Kategori', isCurrent: true,
        }
      ]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex
        mb="30px"
        justify="space-between"
        align="center"
        width="100%"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Kategori
        </BaseText>
        <c-flex gap="3">
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
            left-svg-icon-color="#008C81"
            border-radius="16px"
            variant="outlined"
            @click="onOpenModalSubCategory"
          >
            Tambah Sub Kategori
          </BaseButton>
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
            left-svg-icon-color="white"
            border-radius="16px"
            @click="onOpenModalAddCategory"
          >
            Tambah Kategori
          </BaseButton>
        </c-flex>
      </c-flex>
      <ContentType 
        :type="type"
        @click="(value) => onChangeType(value)"
      />

      <c-flex
        margin-bottom="16px"
        gap="16px"
        width="100%"
        align-items="center"
      >
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          white-space="nowrap"
        >
          List of Kategori
        </BaseText>

        <c-box width="100%">
          <c-form-control
            pos="relative"
            w="100%"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              left="15px"
              height="24px"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="search"
              type="text"
              w="100%"
              height="62px"
              placeholder="Pencarian"
              border-radius="20px"
              padding-left="50px"
              position="relative"
              z-index="1"
              @keyup="onSearch"
            />
          </c-form-control>
        </c-box>
      </c-flex>

      <c-box v-if="isLoading">
        loading...
      </c-box>

      <c-flex
        v-else-if="category.length <= 0"
        w="100%"
        flex-direction="column"
        justify-content="center"
        align-items="center"
      >
        <c-image
          src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
          width="300px"
          mt="3rem"
          mb="1rem"
        />
        <c-text
          mb="1rem"
        >
          Kategori belum ada, silahkan tambahkan kategori.
        </c-text>
      </c-flex>

      <c-box
        v-else
        background="#FFFFFF"
        border="1px solid #F2F2F2"
        box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
        border-radius="12px"
        width="100%"
      >
        <c-box
          id="d-table"
          class="base-table__table"
          as="table"
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
        >
          <c-box
            class="base-table__thead"
            as="thead"
            color="primary.400"
            background-color="primary.50"
          >
            <c-box
              class="base-table__tr"
              as="tr"
            >
              <c-box
                :class="{
                  'base-table__th': true,
                }"
                as="th"
                font-size="14px"
                font-weight="400"
                font-family="Roboto, sans-serif, Arial"
                padding="12px 16px"
                :text-align="'column.align' || 'left'"
              >
                <c-box
                  :width="'column.width' || '100%'"
                  display="flex"
                  align-items="center"
                  gap="8px"
                >
                  <span>{{ 'NO' }}</span>
                </c-box>
              </c-box>
              <c-box
                :class="{
                  'base-table__th': true,
                }"
                as="th"
                font-size="14px"
                font-weight="400"
                font-family="Roboto, sans-serif, Arial"
                padding="12px 16px"
                :text-align="'column.align' || 'left'"
              >
                <c-box
                  :width="'column.width' || '100%'"
                  display="flex"
                  align-items="center"
                  gap="8px"
                >
                  <span>{{ 'KATEGORI' }}</span>
                </c-box>
              </c-box>
              <c-box
                :class="{
                  'base-table__th': true,
                }"
                as="th"
                font-size="14px"
                font-weight="400"
                font-family="Roboto, sans-serif, Arial"
                padding="12px 16px"
                :text-align="'column.align' || 'left'"
              >
                <c-box
                  :width="'column.width' || '100%'"
                  display="flex"
                  align-items="center"
                  gap="8px"
                >
                  <span>{{ 'AKSI' }}</span>
                </c-box>
              </c-box>
            </c-box>
          </c-box>
          <tr
            v-for="(item, itemIndex) in category"
            :key="item.id"
            style="position: sticky;"
          >
            <td style="vertical-align: top;">
              <c-text padding="8px 16px">
                {{ meta.pagesize * (meta.page-1) + itemIndex + 1 }}
              </c-text>
            </td>
            <td style="width: 100%;">
              <c-accordion
                v-if="item?.subCategories?.length >= 1"
                :allow-multiple="true"
                :default-is-open="false"
              >
                <c-accordion-item border="none">
                  <c-accordion-header>
                    <c-box
                      flex="1"
                      text-align="left"
                    >
                      <c-text>{{ item.categoryName || '-' }}</c-text>
                    </c-box>
                    <c-accordion-icon />
                  </c-accordion-header>
                  <c-accordion-panel padding="0">
                    <c-box :height="(item?.subCategories?.length || 0) * 43" />
                    <table
                      id="d-table-nested"
                      style="
                        width: 100%;
                        position: absolute;
                        top: 43px;
                        left: 0;
                      "
                    >
                      <tr
                        v-for="subCategory in item?.subCategories"
                        :key="subCategory.id"
                      >
                        <td style="width: 100%; padding-left: 80px">
                          <c-text>
                            • {{ subCategory.subCategoryName || '-' }}
                          </c-text>
                        </td>
                        <td>
                          <c-flex>
                            <c-button
                              variant-color="primary"
                              variant="ghost"
                              @click="onOpenModalUpdateSubCategory(item, subCategory)"
                            >
                              <inline-svg
                                :src="require('@/assets/icons/icon-edit-2.svg')"
                                height="25"
                                width="25"
                                fill="#008C81"
                              />
                            </c-button>
                            <c-button
                              variant-color="danger"
                              variant="ghost"
                              @click="onOpenModalDelete('sub-category', subCategory)"
                            >
                              <inline-svg
                                :src="require('@/assets/icons/icon-trash.svg')"
                                height="21"
                                width="21"
                                fill="#D32737"
                              />
                            </c-button>
                          </c-flex>
                        </td>
                      </tr>
                    </table>
                  </c-accordion-panel>
                </c-accordion-item>
              </c-accordion>
              <c-text
                v-else
                padding="8px 16px"
              >
                {{ item.categoryName || '-' }}
              </c-text>
            </td>
            <td style="vertical-align: top;">
              <c-flex>
                <c-button
                  variant-color="primary"
                  variant="ghost"
                  @click="onOpenModalUpdateCategory(item)"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-edit-2.svg')"
                    height="25"
                    width="25"
                    fill="#008C81"
                  />
                </c-button>
                <c-button
                  variant-color="danger"
                  variant="ghost"
                  @click="onOpenModalDelete('category', item)"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-trash.svg')"
                    height="21"
                    width="21"
                    fill="#D32737"
                  />
                </c-button>
              </c-flex>
            </td>
          </tr>
        </c-box>
        <c-flex
          justify="space-between"
          align="center"
          border-top="2px solid #CBD5E0"
          padding="8px 16px"
        >
          <c-box>
            <c-flex align="center">
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
              >
                Show
              </BaseText>
              <c-select
                min-width="140px"
                border-radius="8px"
                size="md"
                color="primary.400"
                font-size="18px"
                font-weight="500"
                margin-left="16px"
                :value="$route.query.perpage?.toString() || '10'"
                @change="(perPage_) => handleChangePerpage(perPage_)"
              >
                <option value="5">
                  5 Rows
                </option>
                <option value="10">
                  10 Rows
                </option>
                <option value="15">
                  15 Rows
                </option>
                <option value="20">
                  20 Rows
                </option>
              </c-select>
            </c-flex>
          </c-box>
          <c-box
            font-size="14px"
            font-weight="400"
            font-family="Roboto, sans-serif, Arial"
            padding="12px 0"
            display="flex"
            justify-content="center"
            align-items="center"
            position="relative"
          >
            <BasePagination
              :page="$route.query?.page ? parseInt($route.query?.page) : 1"
              :total-page="calculatePerPage(meta.total, meta.pagesize)"
              @on-change="(page) => handleChangePage(page)"
            />
          </c-box>
        </c-flex>
      </c-box>
    </c-box>

    <!-- Modal Form Category -->
    <BaseModal
      :is-open="isOpenModalCategory"
      :close-on-overlay-click="true"
      :with-button-close="true"
      @close="onCloseModalCategory"
    >
      <template #header>
        <c-text
          py="1rem"
          px="1rem"
          font-size="22px"
        >
          {{ selectedCategory ? 'Edit' : 'Tambah' }} Kategori
        </c-text>
      </template>
      <template #body>
        <c-box px="1rem">
          <BaseInputSelect
            v-model="formCategory.contentTypeId"
            label="Tipe Konten"
            placeholder="Pilih Tipe Konten"
            is-required
            :is-disabled="selectedCategory ? true : false"
            full-width
            :options="types_"
          />

          <BaseInputText
            v-model="formCategory.categoryName"
            label="Kategori"
            placeholder="Masukkan Nama Kategori"
            is-required
          />

          <BaseLabel
            :value="formCategory.image"
            label="Preview Cover Kategori"
            is-required
          >
            <label
              v-chakra="{
                bg: 'white',
                cursor: 'pointer',
                border: '1px solid #C4C4C4',
                boxSizing: 'border-box',
                borderRadius: '6px',
                w: '180px',
                h: '100%',
                minHeight: '180px',
                d: 'flex',
                p: '0',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }"
              for="img-preview"
            >
              <c-image
                v-if="formCategory.image"
                :src="getPreviewImage(formCategory.image)"
                :h="
                  !getPreviewImage(formCategory.image).includes('icon-photo')
                    ? '100%'
                    : '100px'
                "
                w="100px"
                alt="img program"
                mx="auto"
                border-radius="6px"
              />
              <inline-svg
                v-else
                :src="require('@/assets/icons/icon-image-preview.svg')"
                height="100px"
                width="100px"
                fill="#008C81"
              />
            </label>
            <c-input
              id="img-preview"
              style="display: none"
              type="file"
              accept=".jpg, .jpeg, .png"
              @change="onChangeCategoryImage($event)"
            />
          </BaseLabel>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingModal"
            @click="onCloseModalCategory"
          >
            Batal
          </BaseButton>
          <BaseButton
            color="primary.400"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingModal"
            :disabled="isDisabledSubmitCategory"
            @click="onOpenModalConfirmCategory"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- Modal Form Sub Category -->
    <BaseModal
      :is-open="isOpenModalSubCategory"
      :close-on-overlay-click="false"
      :with-button-close="true"
      @close="onCloseModalSubCategory"
    >
      <template #header>
        <c-text
          py="1rem"
          px="1rem"
          font-size="22px"
        >
          {{ selectedCategory ? 'Edit' : 'Tambah' }} Sub Kategori
        </c-text>
      </template>
      <template #body>
        <c-box px="1rem">
          <BaseInputSelect
            v-model="formSubCategory.contentTypeId"
            label="Tipe Konten"
            placeholder="Pilih Tipe Konten"
            is-required
            :is-disabled="selectedSubCategory ? true : false"
            full-width
            :options="types_"
          />
          
          <BaseInputSelect
            v-model="formSubCategory.categoryId"
            label="Kategori"
            :placeholder="`Pilih Kategori`"
            is-required
            :is-disabled="false"
            full-width
            :options="categories_"
          />

          <BaseInputText
            v-model="formSubCategory.subCategoryName"
            label="Sub Kategori"
            placeholder="Masukkan Nama Sub Kategori"
            is-required
          />
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingModal"
            @click="onCloseModalSubCategory"
          >
            Batal
          </BaseButton>
          <BaseButton
            color="primary.400"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingModal"
            :disabled="isDisabledSubmitSubCategory"
            @click="onOpenModalConfirmSubCategory"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- Modal Confirm Category -->
    <BaseModal
      :is-open="isOpenModalConfirmCategory"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/icon-confirm-update.svg')"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            {{ selectedCategory ? 'Apakah anda yakin ingin mengubah kategori?' : 'Apakah anda yakin ingin melakukan penambahan kategori?' }}
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingCategory"
            @click="onCloseModalConfirmCategory"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-confirm.svg')"
            right-svg-icon-color="white"
            color="primary.400"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingCategory"
            @click="onSubmitModalCategory"
          >
            {{ selectedCategory ? 'Simpan' : 'Tambah' }}
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- Modal Confirm Sub Category -->
    <BaseModal
      :is-open="isOpenModalConfirmSubCategory"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/icon-confirm-update.svg')"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            {{ selectedSubCategory ? 'Apakah anda yakin ingin mengubah sub kategori?' : 'Apakah anda yakin ingin melakukan penambahan sub kategori?' }}
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingCategory"
            @click="onCloseModalConfirmSubCategory"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-confirm.svg')"
            right-svg-icon-color="white"
            color="primary.400"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingCategory"
            @click="onSubmitModalSubCategory"
          >
            {{ selectedSubCategory ? 'Simpan' : 'Tambah' }}
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- Modal Alert -->
    <BaseModal
      :is-open="isOpenModalAlert"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="alert.image"
            alt="image success delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            font-weight="bold"
            color="primary.400"
            text-align="center"
          >
            {{ alert.message }}
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
          text-align="center"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            max-width="250px"
            font-size="18px"
            @click="onCloseModalAlert"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <!-- Modal Delete -->
    <BaseModal
      :is-open="isOpenModalConfirmDelete"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            src="https://ik.imagekit.io/dietela/pwa_webp/popup/delete.png?updatedAt=1690890254641"
            alt="image confirmation delete coupon"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            Anda yakin ingin menghapus {{ selectedCategory ? 'kategori' : 'sub-kategori' }} konten “{{ selectedCategory ? selectedCategory?.categoryName : selectedSubCategory?.subCategoryName }}”?
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoadingCategory"
            @click="onCloseModalDelete"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="danger"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingCategory"
            @click="onSubmitModalDelete"
          >
            Hapus
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import ContentType from '@/views/admin/coaching-challenge/_widgets/content-type.vue'
import { CBox, CFlex } from '@chakra-ui/vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseInputText from '@/components/elements/base-input-text.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseLabel from '@/components/elements/base-label.vue'
import BasePagination from '@/components/elements/base-pagination.vue'

export default {
  name: 'ManagementCoachingChallengePage',
  components: {
    BasePagination,
    CBox,
    CFlex,
    BreadcrumbPath,
    BaseButton,
    BaseText,
    ContentType,
    BaseModal,
    BaseInputText,
    BaseInputSelect,
    BaseLabel,
  },
  mixins: [generalMixin],
  data() {
    return {
      page: 1,
      perPage: '10',
      search: this.$route.query.q ?? '',
      type: this.$route.query.type,
      isOpenModalCategory: false,
      isOpenModalSubCategory: false,
      isLoadingModal: false,
      isLoading: false,
      formCategory: {
        contentTypeId: null,
        categoryName: null,
        image: null,
      },
      formSubCategory: {
        contentTypeId: null,
        categoryId: null,
        subCategoryName: null,
      },
      selectedCategory: null,
      selectedSubCategory: null,
      isLoadingCategory: false,
      isOpenModalConfirmCategory: false,
      isOpenModalConfirmSubCategory: false,
      isOpenModalAlert: false,
      isOpenModalConfirmDelete: false,
      alert: {
        image: 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620',
        message: 'Data berhasil ditambah!',
      },
      meta: {
        total: null,
        page: null,
        pagesize: null,
      },
    }
  },
  computed: {
    ...mapState({
      categoryCoaching: (s) => s.admContentChallenge.categoryCoaching,
    }),
    ...mapGetters({
      axios: 'axios',
      types: 'admContentChallenge/types',
      category: 'admContentChallenge/category',
    }),
    types_() {
      if (this.types.length > 0) {
        return this.types.map((it) => {
          return {
            value: it.id,
            label: it.name,
          }
        })
      }
      return []
    },
    categories_() {
      if (this.category.length > 0) {
        return this.category.map((it) => {
          return {
            value: it.id,
            label: it.categoryName,
          }
        })
      }
      return []
    },
    typeId() {
      if (this.types.length > 0 && this.type) {
        return this.types.find((it) => it.type == this.type)?.id
      }
      return undefined
    },
    isDisabledSubmitCategory() {
      if (
        !this.formCategory.contentTypeId ||
        !this.formCategory.categoryName ||
        !this.formCategory.image
      ) {
        return true
      }
      return false
    },
    isDisabledSubmitSubCategory() {
      if (
        !this.formSubCategory.categoryId ||
        !this.formSubCategory.subCategoryName
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    $route: {
      handler() {
        this.loadContentCategories()
      },
    },
    formSubCategory: {
      deep: true,
      handler(val) {
        if (val.contentTypeId && val.contentTypeId != this.typeId) {
          this.onChangeType(this.getTypeSlug(val.contentTypeId), true)
          this.formSubCategory.categoryId = null
        }
      },
    }, 
  },
  async created() {
    if (this.types.length == 0) {
      await this.getContentTypes()
    }
    if (!this.type) {
      this.onChangeType(this.types[0].type, true)
    } else {
      this.loadContentCategories()
    }
  },
  methods: {
    ...mapActions({
      getContentTypes: 'admContentChallenge/getContentTypes',
      getContentCategory: 'admContentChallenge/getContentCategory',
      addContentCategories: 'admContentChallenge/addContentCategories',
      updateContentCategories: 'admContentChallenge/updateContentCategories',
      addContentSubCategories: 'admContentChallenge/addContentSubCategories',
      updateContentSubCategories: 'admContentChallenge/updateContentSubCategories',
      deleteContentCategory: 'admContentChallenge/deleteContentCategory',
      deleteContentSubCategory: 'admContentChallenge/deleteContentSubCategory',
    }),
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    getTypeSlug(typeId) {
      if (this.types.length > 0) {
        return this.types.find((it) => it.id == typeId)?.type
      }
      return undefined
    },
    onChangeType(value, isPage) {
      this.type = value
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          type: value,
          page: isPage && !!this.$route?.query?.page ? parseInt(this.$route?.query?.page) : 1,
        },
      }).catch(() => {})
    },
    handleChangePerpage(perPage_) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          perpage: perPage_,
          page: 1,
        },
      })
    },
    handleChangePage(page_) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: page_,
        },
      })
    },
    async loadContentCategories() {
      this.isLoading = true
      let params = new URLSearchParams()
      params.set('type', this.typeId || this.types[0]?.id)
      params.set('page', this.$route?.query?.page ? parseInt(this.$route?.query?.page) : 1)
      params.set('perpage', this.$route?.query?.perpage ? parseInt(this.$route?.query?.perpage) : 10)
      if (this.$route?.query?.q) {
        params.set('q', this.$route?.query?.q)
      }
      params.set('subCategories', 1)
      this.getContentCategory(params).then((contentCategory) => {
        this.meta = contentCategory?.meta
      }).finally(() => {
        this.isLoading = false
      })
    },
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            q: this.search,
            page: 1,
          },
        })
      }, 1000)
    },
    handleDelete() {
      this.$toast({
        title: 'not implement yet!',
        description: 'not implement yet!',
        status: 'warning',
        variant: 'subtle',
        position: 'bottom-right',
      })
    },
    onUploadFile(payload) {
      let formData = new FormData()
      let fileName = payload?.name || 'file'
      let file = new File([payload], fileName)
      formData.append('file', file, fileName)

      return this.axios
        .post('/v1/admin/content-and-challenge/upload', formData)
        .then((r) => r.data)
    }, 
    getPreviewImage(url) {
      if (typeof url == 'string') {
        return url
      }
      if (url) {
        return URL.createObjectURL(url)
      }
      return require('@/assets/icon-photo.svg')
    },
    onChangeCategoryImage(input) {
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        if (file.type.includes('image') && file.size > 2097152) {
          return this.$toast({
            title: 'Failed',
            description: `This file can't be uploaded, because it (${this.formatBytes(
              file.size,
            )}) exceeds the maximum file size (2 MB).`,
            status: 'error',
            duration: 10000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }
        this.formCategory.image = file
      }
    },

    // Category
    onOpenModalAddCategory() {
      this.formCategory.contentTypeId = this.typeId
      this.isOpenModalCategory = true
    },
    onOpenModalUpdateCategory(data) {
      this.selectedCategory = data
      this.formCategory.contentTypeId = data.contentTypeId
      this.formCategory.categoryName = data.categoryName
      this.formCategory.image = data.image
      this.isOpenModalCategory = true
    },
    onCloseModalCategory() {
      this.isOpenModalCategory = false
      this.onResetFormCategory()
    },
    onOpenModalConfirmCategory() {
      this.isOpenModalConfirmCategory = true
    },
    onCloseModalConfirmCategory() {
      if (!this.isLoadingCategory) {
        this.isOpenModalConfirmCategory = false
      }
    },
    async onSubmitModalCategory() {
      this.isLoadingCategory = true
      let image = null
      if (typeof this.formCategory.image !== 'string') {
        const res = await this.onUploadFile(this.formCategory.image)
        image = res?.data?.url
      } else {
        image = this.formCategory.image
      }

      let data = {
        contentTypeId: this.formCategory.contentTypeId,
        categoryName: this.formCategory.categoryName,
        image: image,
      }

      if (this.selectedCategory) {
        data.id = this.selectedCategory.id
        this.updateContentCategories(data)
          .then(() => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = 'Data kategori berhasil diubah!'
            this.onOpenModalAlert()
            this.loadContentCategories()
          })
          .catch((err) => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = err.data.message || 'Ops! Something when wrong.'
            this.onOpenModalAlert()

            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingCategory = false
            this.onCloseModalConfirmCategory()
            this.onCloseModalCategory()
          })
      } else {
        this.addContentCategories(data)
          .then(() => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = 'Data kategori berhasil ditambah!'
            this.onOpenModalAlert()
            this.loadContentCategories()
          })
          .catch((err) => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = err.data.message || 'Ops! Something when wrong.'
            this.onOpenModalAlert()
  
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingCategory = false
            this.onCloseModalConfirmCategory()
            this.onCloseModalCategory()
          })
      }
    },
    onResetFormCategory() {
      this.selectedCategory = null
      this.formCategory.contentTypeId = null
      this.formCategory.categoryName = null
      this.formCategory.image = null
    },

    // Sub Category
    onOpenModalSubCategory() {
      this.formSubCategory.contentTypeId = this.typeId
      this.isOpenModalSubCategory = true
    },
    onOpenModalUpdateSubCategory(category, sub) {
      this.selectedCategory = category
      this.selectedSubCategory = sub
      this.formSubCategory.contentTypeId = category.contentTypeId
      this.formSubCategory.categoryId = sub.categoryId
      this.formSubCategory.subCategoryName = sub.subCategoryName
      this.isOpenModalSubCategory = true
    },
    onCloseModalSubCategory() {
      this.isOpenModalSubCategory = false
      this.onResetFormSubCategory()
    },
    onOpenModalConfirmSubCategory() {
      this.isOpenModalConfirmSubCategory = true
    },
    onCloseModalConfirmSubCategory() {
      if (!this.isLoadingCategory) {
        this.isOpenModalConfirmSubCategory = false
      }
    },
    onSubmitModalSubCategory() {
      this.isLoadingCategory = true
      if (this.selectedSubCategory) {
        const data = {
          id: this.selectedSubCategory?.id,
          categoryId: this.formSubCategory.categoryId,
          subCategoryName: this.formSubCategory.subCategoryName,
        }
        this.updateContentSubCategories(data)
          .then(() => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = 'Data sub kategori berhasil diubah!'
            this.onOpenModalAlert()
            this.loadContentCategories()
          })
          .catch((err) => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = err.data.message || 'Ops! Something when wrong.'
            this.onOpenModalAlert()

            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingCategory = false
            this.onCloseModalConfirmSubCategory()
            this.onCloseModalSubCategory()
          })
      } else {
        const data = {
          categoryId: this.formSubCategory.categoryId,
          subCategoryName: this.formSubCategory.subCategoryName,
        }
        this.addContentSubCategories(data)
          .then(() => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = 'Data sub kategori berhasil ditambah!'
            this.onOpenModalAlert()
            this.loadContentCategories()
          })
          .catch((err) => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = err.data.message || 'Ops! Something when wrong.'
            this.onOpenModalAlert()

            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingCategory = false
            this.onCloseModalConfirmSubCategory()
            this.onCloseModalSubCategory()
          })
      }
    },
    onResetFormSubCategory() {
      this.selectedCategory = null
      this.selectedSubCategory = null
      this.formSubCategory.contentTypeId = null
      this.formSubCategory.categoryId = null
      this.formSubCategory.subCategoryName = null
    },

    // Alert
    onOpenModalAlert() {
      this.isOpenModalAlert = true
    },
    onCloseModalAlert() {
      this.isOpenModalAlert = false
    },

    // Delete
    onOpenModalDelete(type, data) {
      if (type == 'category') {
        this.selectedCategory = data
      } else {
        this.selectedSubCategory = data
      }
      this.isOpenModalConfirmDelete = true
    },
    onCloseModalDelete() {
      if (!this.isLoadingCategory) {
        this.isOpenModalConfirmDelete = false
        this.selectedCategory = null
        this.selectedSubCategory = null
      }
    },
    onSubmitModalDelete() {
      this.isLoadingCategory = true
      if (this.selectedCategory) {
        this.deleteContentCategory(this.selectedCategory?.id)
          .then(() => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = 'Data kategori berhasil dihapus!'
            this.onOpenModalAlert()
            this.loadContentCategories()
          })
          .catch((err) => {
            const name = this.selectedCategory?.categoryName
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_access_denied_re_awnf%201.webp?updatedAt=1697518229611'
            this.alert.message = err.data.message == 'Categories Found' ? `Kategori tidak dapat dihapus karena terdapat konten dengan kategori “${name}”` : err.data.message 
            this.onOpenModalAlert()

            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingCategory = false
            this.onCloseModalDelete()
          })
      } else {
        this.deleteContentSubCategory(this.selectedSubCategory?.id)
          .then(() => {
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/popup/berhasil_data.png?updatedAt=1690890254620'
            this.alert.message = 'Data sub-kategori berhasil dihapus!'
            this.onOpenModalAlert()
            this.loadContentCategories()
          })
          .catch((err) => {
            const name = this.selectedSubCategory?.subCategoryName
            this.alert.image = 'https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_access_denied_re_awnf%201.webp?updatedAt=1697518229611'
            this.alert.message = err.data.message == 'Sub Categories Found' ? `Sub-kategori tidak dapat dihapus karena terdapat konten dengan sub-kategori “${name}”` : err.data.message 
            this.onOpenModalAlert()

            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isLoadingCategory = false
            this.onCloseModalDelete()
          })
      }
    },
  },
}
</script>

<style scoped>
.base-table__table::v-deep {
  border-collapse: separate;
  border: solid #EDF2F7 1px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.base-table__tbody::v-deep .base-table__tr:not(:last-child) .base-table__td {
  border-bottom: solid #CBD5E0 2px;
}

#d-table > tr:not(:last-child) > td {
//padding: 8px 0;
  border-bottom: solid #CBD5E0 1px;
}

#d-table-nested > tr > td {
//padding: 8px 0;
  border-top: solid #CBD5E0 1px;
}

</style>
